<template>
  <div class="service">
    <section class="my-banner">
      <div class="banner-content">
        <h1>拎包办公平台</h1>
        <ul>
          <li class="u-banner-des">
            <div class="left">
              <img src="@/assets/images/left1.png" alt="">
            </div>
            <div class="right">
              <p>找办公室</p>
              <p>让每一个企业主找到自己喜欢的场地</p>
            </div>
          </li>
          <li class="u-banner-des">
            <div class="left">
              <img src="@/assets/images/left2.png" alt="">
            </div>
            <div class="right">
              <p>妈妈财税</p>
              <p>让每一位财税妈妈有自己喜欢的工作</p>
            </div>
          </li>
          <li class="u-banner-des">
            <div class="left">
              <img src="@/assets/images/left3.png" alt="">
            </div>
            <div class="right">
              <p>企业服务</p>
              <p>让每一家入驻企业有着舒心的服务</p>
            </div>
          </li>
        </ul>
        <!-- <h1>产品解决方案</h1> -->
        <!-- <p class="u-banner-des">
          汇盛金丰为广州目前规模最大的服务型国家级孵化器之一，将以北京、上海、深圳、广州为中心，覆盖150个城市的全国化战略布局，通过多样化、定制化场景化的服务持续为企业或物业方赋能或创造价值。
        </p> -->
      </div>
    </section>
    <section class="fuwu-bar-fat" id="fuwu-bar-fat">
      <div class="fuwu-bar">
        <h2 class="u-product-tt">企业服务</h2>
        <div class="g-main-content">
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">财税服务</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >企业注册怎么办？交给妈妈财税，安全放心又省钱！</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">新媒体推广</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >客户用户画像精准分析，线上线下裂变活动策略，增长模型全方位服务，拎包办公平台为您助力“品
                效 销”增长</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">金融服务</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >深入园区 服务小微 携手共赢未来，拎包办公平台让您放心选择</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">企业装修</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >环保▪艺术▪生活
                在工作中享受，在享受中生活，拎包办公平台让您天天住氧吧</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">办公家具</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >完美办公源于“拎包办公”，拎包办公平台为您私享定制化服务</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">设备租赁</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >“租”你一臂之力
                “赁”辟广阔天地，拎包办公平台为您节省企业成本</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">法律咨询</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >让正义多一点智慧
                让大家多一份安全，拎包办公平台为您全方位保护</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">知识产权</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >让我们抵制盗版 尊重智慧的结晶，拎包办公平台为您保驾护航</span
              >
            </div>
          </div>
          <div class="g-content">
            <div class="u-con-title">
              <span class="u-title">软件及小程序开发</span>
            </div>
            <div class="u-con-desc">
              <span class="u-desc"
                >为您私享定制化软件开发服务，助力于“拎包办公”，</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="caishui-bar-fat" id="caishui-bar-fat">
      <div class="caishui-bar">
        <h2 class="u-product-tt">妈妈财税</h2>
        <div class="content">
          <el-row :gutter="20">
            <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/caishui1.png" />
                </div>
                <div class="right">
                  <p class="u-title">公司注册</p>
                  <p class="u-title2">为什么要注册公司</p>
                  <p class="u-desc">经营主体合法化; 便于融资和信贷;承担有限法律责任;建立企业品牌;购买社保;推广产品和服务</p>
                  <p class="u-title2">产品介绍</p>
                  <p class="u-desc">有限责任公司是由五十个以下的股东出资设立，每个股东以其所认缴的出资额对公司承担有限责任，公司法人以其全部资产对公司债务承担全部责任的经济组织。股东对公司的债务只承担有限的责任，承担的最高额度就是公司的注册资本。</p>
                  <p class="u-title2">服务周期 </p>
                  <p class="u-desc">7个工作日</p>
                  <p class="u-title2">所需材料 </p>
                  <p class="u-desc">填写我方提供的客户信息表</p>
                  <p class="u-desc">给公司拟定名称（地区+字号+行业+组织形式）</p>
                  <p class="u-desc">法定代表人、监事、所有股东身份证明复印件</p>
                   <p class="u-desc">住所使用证明（使用自有房产的，提交房屋产权证明复印件;使用非自有房产的，提交业主房屋产权证明复印件和房屋租赁协议或者无偿使用证明复印件。）</p>
                   <p class="u-desc">法律、行政法规和国务院决定规定设立公司必须报经批准的或公司申请登记的经营范围中有法律、行政法规和国务院决定规定必须在登记前报经批准的项目，提交有关批准文件或者许可证件的复印件。</p>
                   <p class="u-title2">公司成立后需要做什么 </p>
                   <p class="u-desc">记账报税→申请税控及发票→开设银行账户→社保开户→资质办理→商标注册</p>
                   <p class="u-title2">注意事项</p>
                   <p class="u-desc">1、执照审批过程中，工商会抽查注册地址是否真实，如驳回提交流程，需携带场所证明全体股东到场做实审办公场地</p>
                   <p class="u-desc">2、股东不能是黑名单（严重失信人员名单或名下有案件未完结的人员）</p>
                   <p class="u-desc">3、个人银行网银U盾预留电话要和现在使用的保持一致</p>
                </div>
              </div>
            </el-col>
            <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/caiwu2.png" />
                </div>
                <div class="right">
                  <p class="u-title">代理记账</p>
                  <p class="u-title2">产品介绍</p>
                  <p class="u-desc">代理记账是指将本企业的会计核算、记账、报税等一系列的工作全部委托给专业记账公司完成，本企业只设立出纳人员，负责日常货币收支业务和财产保管等工作。</p>
                  <p class="u-title2">服务内容</p>
                  <p class="u-desc">
                    每月做账、财务核算、税务申报、出具报表等，精心推荐行业自身会计提供优质的全流程代账服务
                  </p>
                  <p class="u-desc">整理原始凭证
                  </p>
                   <p class="u-desc">财税专业对所有数据资料审核</p>
                   <p class="u-desc">财务管理咨询建议</p>
                   <p class="u-desc">每月纳税申报</p>
                   <p class="u-desc">税务申报及时推送、出具财务报表</p>
                   <p class="u-desc">月度装订凭证、年末装订账簿</p>
                   <p class="u-desc">日常基本财务、财税咨询、财税风险预警</p>
                  <p class="u-title2">所需资料</p>
                    <p class="u-desc">（温馨提示∶跟进税务系统规定时间每月15日之前完成申报，法定节假日依次顺延申报时间）</p>
                    <p class="u-desc">工商执照副本复印件</p>
                    <p class="u-desc">法人、股东身份证复印件</p>
                    <p class="u-desc">银行开户许可证复印件</p>
                    <p class="u-desc">电子税务局报税账号、密码</p>
                    <p class="u-desc">非新注册的公司交接原会计资料（账套，会计报表，税控盘等）</p>
                    <p class="u-title2">办理流程</p>
                    <p class="u-desc">沟通咨询确定服务→签订代账服务合同→快递收取票据→专属会计开始做账→按时纳税申报→智能推送财务报表→实时监控财务状况→日常财务咨询服务</p>
                    <p class="u-title2">服务周期</p>
                    <p class="u-desc">—年</p>
                    <p class="u-title2">注意事项</p>
                    <p class="u-desc">请确保提供资料的真实性</p>
                </div>
              </div>
            </el-col>
            <!-- <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/caiwu3.png" />
                </div>
                <div class="right">
                  <p class="u-title">产品标题XXXXXX</p>
                  <p class="u-desc">
                    产品描述内容XXXXXXXXXX这是文字文字，产品描述内容XXXXXXXXXX这是文字文字
                  </p>
                </div>
              </div>
            </el-col> -->
            <!-- <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/caiwu5.png" />
                </div>
                <div class="right">
                  <p class="u-title">产品标题XXXXXX</p>
                  <p class="u-desc">
                    产品描述内容XXXXXXXXXX这是文字文字，产品描述内容XXXXXXXXXX这是文字文字
                  </p>
                </div>
              </div>
            </el-col> -->
          </el-row>
        </div>
      </div>
    </section>


    <!-- <section class="lingbao-bar-fat">
      <div class="lingbao-bar">
        <h2 class="u-product-tt">拎包办公</h2>
        <div class="content">
          <el-row :gutter="20">
            <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/lingbao3.png" />
                </div>
                <div class="right">
                  <p class="u-title">方案标题XXXXXX</p>
                  <p class="u-desc">
                    为客户寻找产业园区、写字楼、共享办公、商铺、公寓、住宅及集群卡位地址服务
                  </p>
                </div>
              </div>
            </el-col>
            <el-col :lg="12" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="my-wraper">
                <div class="left">
                  <img src="@/assets/images/lingbao1.png" />
                </div>
                <div class="right">
                  <p class="u-title">产品标题XXXXXX</p>
                  <p class="u-desc">
                    产品描述内容XXXXXXXXXX这是文字文字，产品描述内容XXXXXXXXXX这是文字文字
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section> -->

    <section class="fangan-bar-fat">
      <div class="fangan-bar">
        <h2 class="m-quotes-title">方案定制化</h2>
        <div class="m-quotes-content">
          <el-row :gutter="20">
            <el-col :lg="6" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="exc-quotes-li">
                <img class="item-img1" src="@/assets/images/user.png" />
                <div class="item-name">面向具体需求提供咨询服务</div>
                <img
                  class="item-img2"
                  src="@/assets/images/point.png"
                />
              </div>
            </el-col>
             <el-col :lg="6" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="exc-quotes-li">
                <img class="item-img1" src="@/assets/images/quotes2.png" />
                <div class="item-name">深度剖析用户需求</div>
                <img
                  class="item-img2"
                  src="@/assets/images/point.png"
                />
              </div>
            </el-col>
             <el-col :lg="6" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="exc-quotes-li">
                <img class="item-img1" src="@/assets/images/quotes3.png" />
                <div class="item-name">个性化结合企业业务</div>
                <img
                  class="item-img2"
                  src="@/assets/images/point.png"
                />
              </div>
            </el-col>
             <el-col :lg="6" :xs="24" :sm="24" :md="24" class="col-father">
              <div class="exc-quotes-li">
                <img class="item-img1" src="@/assets/images/quotes4.png" />
                <div class="item-name">量身定制解决方案</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    // HelloWorld,
  },
  mounted() {
    const queryName = this.$route.query.anchor;
    if (queryName) {
      this.custormAnchor(queryName)
    }
  },
  methods: {
    custormAnchor(index) { //锚点滚动到固定位置      
     let anchorElement = document.getElementById(index);
     if(anchorElement) { anchorElement.scrollIntoView(); }               
    },
  },
};
</script>

<style lang="scss" scoped>
.w3l-service-breadcrum .breadcrum-bg h2 {
  font-size: 36px;
}

.isleft {
  text-align: left;
}

.my-banner {
  height: 450px;
  overflow: hidden;
  background: url(../assets/images/banner3.png) center no-repeat;
  background-size: cover;

  .banner-content {
    padding-top: 120px;
    max-width: 1180px;
    margin: 0 auto;



    h1 {
      font-size: 32px;
      color: #fff;
      text-align: left;
      letter-spacing: 2px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
    }

    .u-banner-des {
      display: flex;
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
      line-height: 1.5;
      margin-top: 25px;
      .left {
        width: 42px;
        height: 40px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // .u-banner-des {
    //   padding-top: 18px;
    //   margin-bottom: 60px;
    //   max-width: 570px;
    //   color: #fff;
    //   font-weight: lighter;
    //   font-size: 14px;
    //   line-height: 1.5;
    //   // max-height: 63px;
    //   display: -webkit-box;
    //   -webkit-box-orient: vertical;
    //   -webkit-line-clamp: 3;
    //   // overflow: hidden;
    // }
  }
}

.caishui-bar-fat {
  background-color: #f5f7fa;
}

.caishui-bar {
  max-width: 1180px;
  margin: 0 auto;
  padding: 57px 0 70px;

  .u-product-tt {
    display: inline-block;
    font-size: 24px;
    text-align: left;
    color: #333;
    height: 30px;
    line-height: 30px;
    vertical-align: bottom;
    padding-bottom: 9px;
  }

  .content {
    .my-wraper {
      display: flex;
      padding-top: 26px;
      .left {
        width: 70px;
        height: 70px;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 450px;
        margin-left: 20px;

        .u-title {
          font-size: 16px;
          font-weight: 700;
          height: 18px;
          line-height: 18px;
          color: #333;
          width: 100%;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .u-title2 {
          font-size: 14px;
          font-weight: 600;
          height: 18px;
          line-height: 18px;
          color: #606266;
          width: 100%;
          margin-top: 8px;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .u-desc {
          font-size: 14px;
          line-height: 21px;
          text-align: left;
          color: #666;
          padding-top: 5px;
          width: 100%;
          // -webkit-box-orient: vertical;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}

.fuwu-bar-fat {
  padding: 57px 0 70px;
  background-color: #f0f3f7;
}

.fuwu-bar {
  position: relative;
  max-width: 1180px;
  padding: 0 10px;
  margin: 0 auto;

  .u-product-tt {
    display: inline-block;
    font-size: 24px;
    text-align: left;
    color: #333;
    height: 30px;
    line-height: 30px;
    vertical-align: bottom;
    padding-bottom: 9px;
  }

  .g-main-content {
    margin-top: 26px;
    .g-content {
      margin-top: 14px;
      width: 100%;
      min-height: 90px;
      border-radius: 2px;
      border: 1px solid #e4e7ef;
      background-color: #fff;
      padding: 0 30px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;

      .u-con-title {
        padding-top: 24px;
        position: relative;
        width: 100%;
        // overflow: hidden;

        .u-title {
          display: inline-block;
          font-size: 16px;
          color: #333;
          font-weight: 700;
          line-height: 18px;
          max-height: 18px;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .u-con-desc {
        // line-height: 1.71;
        width: 100%;
        // padding-top: 3px;

        .u-desc {
          display: inline-block;
          font-size: 14px;
          color: #666;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .g-content:first-child {
      margin-top: 0;
    }
  }
}

.lingbao-bar-fat {
  padding: 57px 0 70px;
  background-color: #fff;

  .lingbao-bar {
    position: relative;
    max-width: 1180px;
    padding: 0 10px;
    margin: 0 auto;

    .u-product-tt {
      display: inline-block;
      font-size: 24px;
      text-align: left;
      color: #333;
      height: 30px;
      line-height: 30px;
      vertical-align: bottom;
      padding-bottom: 9px;
    }

    .content {
      .my-wraper {
        display: flex;
        padding-top: 26px;
        .left {
          width: 70px;
          height: 70px;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
        .right {
          width: 450px;
          margin-left: 20px;

          .u-title {
            font-size: 16px;
            font-weight: 700;
            height: 18px;
            line-height: 18px;
            color: #333;
            width: 100%;
            // overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .u-desc {
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            color: #666;
            padding-top: 8px;
            width: 100%;
            max-height: 38px;
          
          }
        }
      }
    }
  }
}

.fangan-bar-fat {
  overflow: hidden;
  height: 270px;
  background-image: -webkit-linear-gradient(left, #326ec9, #34babf);
  //background-image: -moz- oldlinear-gradient(left, #326ec9, #34babf);
  background-image: linear-gradient(90deg, #326ec9, #34babf);
  .fangan-bar {
    position: relative;
    max-width: 1180px;
    padding: 0 10px;
    margin: 0 auto;
    .m-quotes-title {
      padding: 50px 0 45px;
      font-size: 26px;
      color: #fff;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .exc-quotes-li {
      max-width: 320px;
      zoom: 1;
      height: 50px;
      display: flex;
      .item-img1 {
        display: block;
        width: 50px;
        height: 50px;
      }

      .item-name {
        // width: 134px;
        padding-top: 4px;
        padding-left: 16px;
        padding-right: 10px;
        line-height: 20px;
        // max-height: 40px;
        letter-spacing: 0.3px;
        color: #fff;
        font-size: 14px;
      }
    .item-img2 {
      display: block;
      padding-top: 22px;
      // padding-right: 35px;
      width: 136px;
      height: 25px;
    }
    }
  }
}

@media screen and (max-width: 768px) {
  .my-banner {
    height: 320px;
    .banner-content {
      padding: 40px 20px 40px;
      h1 {
        font-size: 20px;
      }
    }
  }

  .caishui-bar {
    padding-left: 20px;
    padding-right: 20px;

    .u-product-tt {
      font-size: 18px;
    }
    
  }

  .fuwu-bar {
    .u-product-tt {
      font-size: 18px;
    }
    .g-main-content {
    .g-content {
      .u-con-title {
        padding: 14px 0 10px;
      }

      .u-con-desc{
        .u-desc {
          white-space: normal;
          padding-bottom: 13px;
        }
      }
    }
    }
  }

  .lingbao-bar-fat {
    padding: 30px 20px 35px;
    .lingbao-bar {
      .u-product-tt {
      font-size: 18px;
    }
    .content {
      .col-father {
        margin-bottom: 15px;
      }
    }
    }
  }

  .fangan-bar-fat {
    padding: 0 20px;
    height:auto;
    .fangan-bar {
      .m-quotes-title{
        padding: 28px 0 35px;
        font-size: 18px;
        font-weight: 700;
      }

      .m-quotes-content {
        .col-father {
          margin-bottom: 15px;
        }
        .exc-quotes-li {
          .item-img2 {
            display: none;
          }
        }
      }
    }
  }

  

}
</style>